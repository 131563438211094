import styled from '@emotion/styled';

import { lightenDarkenColor } from 'utils/colors';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;

    input {
        width: 100%;
        height: 3em;
        border: none;
        padding: 0 1em;
        color: ${vars.colorTextDark};
        border-radius: .5em;
        font-size: .9em;

        &[type='checkbox'] {
            position: relative;
            height: 2em;
            max-width: 2em;
            width: 2em;
            max-height: 2em;
            padding: 0;
			background-color: ${vars.colorTextWhite};
			border-radius: 0.2em;
			vertical-align: middle;
			border: 0.15em solid ${vars.colorTextWhite};
			appearance: none;
			-webkit-appearance: none;
			outline: none;
			cursor: pointer;
            transition: background 0.3s ease-in-out;

            &:before {
                content: '✓';
                position: absolute;
                top: 50%;
                left: 5%;
                transform: translate(50%, -50%);
                color: ${vars.colorTextWhite};
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                font-size: 1em;
            }
            
            &:checked {
                background: ${vars.backgroundGradient180};

                &:before {
                    opacity: 1;
                }
                
            }
		}

        &::placeholder {
            color: ${lightenDarkenColor(vars.colorPrimary, -100)};
        }

        &:disabled {
            background-color: #d7e2e4;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }

    &.decorator-left {
        input {
            padding-left: 2.5em;
        }
    }
    &.decorator-right {
        input {
            padding-right: 2.5em;
        }
    }

    &.style-secondary {
        .internal-wrapper {
            border: none;
        }

        input {
            width: 100%;
            padding: 0.6em 1.5em;
            border-radius: 5px;
            outline: none;
            border: none;
            height: 50px;
            font: normal normal normal 14px/18px Montserrat;

            &::placeholder {
                color: ${vars.colorTextGray};
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        font-size: 1.1em;
    }
`;
