import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    text-align: left;
    position: relative;
    display: block;
    width: 100%;
    font-size: .8em;
    color: ${vars.colorAlert};
    line-height: 80%;
    font-weight: 500;
    margin: 0.3em 0 ;
`;
