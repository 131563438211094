import { FunctionComponent } from 'react';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutFormsInpuRowl: FunctionComponent<Props> = ({ children, className, error, errorMessage }) => {
    return (
        <StyledComponent
            className={classnames(
                'layout-forms-input-row',
                className,
                { error },
            )}
        >
            {errorMessage && (
                <span className="input-row-error">
                    {errorMessage}
                </span>
            )}
            {children}
        </StyledComponent>
    );
};

export default LayoutFormsInpuRowl;