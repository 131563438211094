import { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';

import InputError from 'components/layout/forms/InputError';
import InputLabel from 'components/layout/forms/InputLabel';

import StyledComponent from './styles';
import { InputDecorator, Props } from './types';

const LayoutFormsInputWrapper: FunctionComponent<Props> = ({ children, label, error, decoratorLeft, decoratorRight, type }) => {
    const getDecorator = (decorator: InputDecorator, position: string): ReactNode => {
        if (decorator?.visible !== true) return null;

        return (
            <div
                className={classnames(
                    'input-decorator',
                    `input-decorator-${position}`
                )}
            >
                {decorator?.children || null}
            </div>
        );
    };

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-input-wrapper',
                { error: Boolean(error) },
                `type-${type}`
            )}
        >
            {label && (
                <div className="label-wrapper">
                    {label && (
                        <InputLabel>
                            {label}
                        </InputLabel>
                    )}
                </div>
            )}
            <div className="internal-wrapper">
                {getDecorator(decoratorLeft, 'left')}
                <div className="input-body">
                    {children}
                </div>
                {getDecorator(decoratorRight, 'right')}
            </div>
            <InputError>
                {error}
            </InputError>
        </StyledComponent>
    );
};

export default LayoutFormsInputWrapper;
