export const emailSoftValidator = (value: string): boolean => {
    const atLeastOneCharacter = value.length > 0;
    const emailMark = value.indexOf('@') !== -1;
    const fromEmailMark = emailMark ? value.substring(value.indexOf('@')) : '';
    const characterAfterEmailMark = emailMark ? value.substring(value.indexOf('@'), value.length).length : 0;
    const emailDot = fromEmailMark.indexOf('.') !== -1;
    const dotAfterEmailMark = (emailMark && emailDot) ? fromEmailMark.substring(fromEmailMark.indexOf('.'), fromEmailMark.length).length : 0;

    return (atLeastOneCharacter && (emailMark && characterAfterEmailMark > 1) && (emailDot && dotAfterEmailMark > 2));
};
