import React, { FormEvent, FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { emailSoftValidator } from 'utils/emailSoftValidator';

import { createLead, CreateLeadResponse } from 'requests/customers';

import Button from 'components/layout/Button';
import { ButtonStyles, ButtonTypes, ButtonVariants } from 'components/layout/Button/types';
import Form from 'components/layout/forms/Form';
import Input from 'components/layout/forms/Input';
import { InputStyles } from 'components/layout/forms/Input/types';
import InputRow from 'components/layout/forms/InputRow';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';
import Spinner, { Colors as SpinnerColors } from 'components/layout/Spinner';

import StyledComponent from './styles';
import { Props } from './types';

export interface CustomerAgree {
    type: string;
    content: string;
    value: boolean;
}

export interface FormState {
    email: string;
    customerAgrees: CustomerAgree[];
}

const ContentFormsNewsletter: FunctionComponent<Props> = ({ translations }) => {
    const [formState, setFormState] = useState<FormState>(null);
    const [formErrors, setFormErrors] = useState<FormState>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formSubmitted, setFormSubmitted]: [boolean, Function] = useState(false);

    const onSubmit = (event: FormEvent<HTMLFormElement>): Promise<CreateLeadResponse> | void => {
        event.preventDefault();

        if (!emailSoftValidator(formState?.email)) {
            return setFormErrors({ ...formErrors, email: translations.formWrongEmail });
        }

        setFormErrors(null);
        setIsLoading(true);

        return createLead({
            ...formState,
        }).then((response: CreateLeadResponse) => {
            setFormState(null);

            return response;
        }).catch((error) => {
            console.error(error);
            setFormErrors(error?.payload?.validationErrors);
            return null;
        }).finally(() => {
            setIsLoading(false);
            setFormSubmitted(true);
        });
    };

    const emailInputWithValidator = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as string;

        setFormErrors({ ...formErrors, email: null });
        setFormState({ ...formState, email: value });
    };

    return (
        <StyledComponent className="content-forms-newsletter">
            <LayoutContainer size={Sizes.Medium}>
                <div className="left">
                    <div className="headline">
                        <h2>
                            {translations?.headline}
                        </h2>
                    </div>
                    <div className="subHeadline">
                        <h3>
                            {translations?.subHeadline}
                        </h3>
                    </div>
                </div>
                <div className="right">
                    {isLoading && (<Spinner color={SpinnerColors.Violet} />)}
                    {!formSubmitted && (
                        <Form
                            onSubmit={onSubmit}
                            className="form"
                        >
                            <Input
                                name="email"
                                style={InputStyles.Secondary}
                                value={formState?.email}
                                error={formErrors?.email}
                                placeholder={'Twój adres e-mail'}
                                onChange={emailInputWithValidator}
                            />
                            <InputRow>
                                <div className="submit-container">
                                    <div
                                        className="agreement"
                                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                                            if ((e.target as Element).classList.contains('link-tag')) return;

                                            const agrees = [{
                                                type: 'regulations',
                                                value: true,
                                                content: 'Potwierdzam że zapoznałem się z regulaminem i wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z Polityką prywatności',
                                            }, {
                                                type: 'marketingEmail',
                                                value: true,
                                                content: 'Chcę otrzymywać e-maile z aktualizacjami i akceptuję warunki',
                                            }];

                                            if (formState?.customerAgrees) {
                                                setFormState({ ...formState, customerAgrees: null });
                                            } else setFormState({ ...formState, customerAgrees: agrees });
                                        }}
                                    >
                                        <div
                                            className={classnames({
                                                'agreement__box': true,
                                                'checked': Boolean(formState?.customerAgrees),
                                            })}
                                        />
                                        <div className="agreement__text">
                                            {translations?.formAgreement}
                                            {' '}
                                            <Link href={Routes.PublicPolicyPrivacy}>
                                                {translations?.formAgreementSpan}
                                            </Link>
                                            {' '}
                                            {translations?.formAgreementConjunction}
                                            <Link href={Routes.PublicRegulations}>
                                                {translations?.formAgreementSecondSpan}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    className="gradient-180"
                                    style={ButtonStyles.Primary}
                                    variant={ButtonVariants.Primary}
                                    type={ButtonTypes.Submit}
                                    disabled={!formState?.email || !formState?.customerAgrees}
                                >
                                    {translations?.formSubmit}
                                </Button>
                            </InputRow>
                        </Form>
                    )}
                    {formSubmitted && (
                        <div className="success-info">
                            <p
                                className="succcess-info-text"
                                dangerouslySetInnerHTML={{ __html: 'Dziękujemy za zapisanie się do naszego newslettera' }}
                            />
                        </div>
                    )}
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default ContentFormsNewsletter;
