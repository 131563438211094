import styled from '@emotion/styled';

import { lightenDarkenColor } from 'utils/colors';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0.5em;
    position: relative;

    .layout-forms-input-error {
        position: relative;
        top: 100%;
    }

    .label-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.3em;
    }

    .internal-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: relative;
        border-radius: 0.5em;
        border: 0.1em solid ${lightenDarkenColor(vars.colorTextLight, -100)};

        .input-decorator {
            position: absolute;
            top: 0;
            z-index: 2;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1.5em;
            font-weight: 600;

            a,
            button {
                cursor: pointer;
            }

            &.input-decorator-left {
                left: 0;
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
            }

            &.input-decorator-right {
                right: 0;
                border-top-right-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
            }
        }

        .input-body {
            width: 100%;

            &.left-decorator-error {
                padding-left: 1.5em;
            }
        }
    }

    &.type-checkbox {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: 1em;

        .internal-wrapper {
            max-width: max-content;
            order: 1;
        }

        .label-wrapper {
            max-width: 80%;
            order: 2;
        }
    }

    &.error {
        .internal-wrapper {
            .input-body {
                border-radius: 5px;
                outline: 1px solid ${vars.colorAlert};

                .input {
                    border-radius: 5px !important;
                }
            }
        }
    }
`;
