import { FunctionComponent } from 'react';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutFormsInputError: FunctionComponent<Props> = ({ children }) => {
    if (!children) return null;

    return (
        <StyledComponent className="layout-forms-input-error">
            {children}
        </StyledComponent>
    );
};

export default LayoutFormsInputError;;