import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    position: relative;

    > * {
        margin-right: 1em;

        &:last-of-type {
            margin-right: 0;
        }
    }

    .input-row-error {
        position: absolute;
        left: .3em;
        bottom: .3em;
        color: red;
        font-size: .9em;
        font-weight: 600;
    }

    &.error {
        background: white;
        border: .1em solid red;
        padding: .5em;
        border-radius: .5em;
    }
`;
